<template>
    <div class="app">
        <Header class="app__header"/>

        <section :id="sectionList.main.id" class="app__section main">
            {{sectionList.main.title}}
        </section>
        <section :id="sectionList.contacts.id" class="app__section contacts">
            {{sectionList.contacts.title}}
        </section>
        <section :id="sectionList.footer.id" class="app__section footer">
            {{sectionList.footer.title}}
        </section>

        <FeedbackForm
            v-if="isShowForm"
            class="app__form"
        />

        <UpArrow />
    </div>
</template>

<script>
import FeedbackForm from "@/components/FeedbackForm/FeedbackForm.vue";
import Header from "@/components/Header/Header.vue";
import {SECTIONS} from "@/constants/sections";
import UpArrow from "@/components/UpArrow/UpArrow.vue";

export default {
    name: 'App',
    data() {
        return {
            isShowForm: false,
        }
    },
    constants: {SECTIONS},
    components: {
        UpArrow,
        Header,
        FeedbackForm,
    },
    computed: {
        sectionList() {
            return SECTIONS;
        },
    },
}
</script>

<style lang="scss">
@import "style/main";
</style>
