export const SECTIONS = {
    main: {
        id: 'main',
        title: 'Главная',
        link: 'main',
    },
    contacts: {
        id: 'contacts',
        title: 'Контакты',
        link: 'contacts',
    },
    footer: {
        id: 'footer',
        title: 'Футер',
        link: 'footer',
    },
};
