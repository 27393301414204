<template>
    <transition name="fade">
        <div
            v-if="showArrow"
            @click="scrollToTop"
            class="up-arrow"
        >
            вверх
        </div>
    </transition>
</template>

<script>
export default {
    name: "UpArrow",
    data() {
        return {
            showArrow: false,
        }
    },
    created () {
        window.addEventListener('scroll', this.handlerScroll);
    },
    destroyed () {
        window.removeEventListener('scroll', this.handlerScroll);
    },
    methods: {
        scrollToTop() {
            document.querySelector("body")
                .scrollIntoView({
                    block: 'start',
                    behavior: 'smooth',
                });
        },
        handlerScroll() {
            this.showArrow = window.scrollY > 50;
        },
    }
}
</script>

<style lang="scss" scoped>
@import "style/up-arrow";
</style>