<template>
    <div
        :class="menuClasses"
        class="menu"
    >
        <div class="menu__buttons">
            <button
                @click="handlerShowMenu"
                class="menu__button"
            >
                меню
            </button>
        </div>

        <ul
            class="menu__list menu-list"
        >
            <li
                v-for="item in menuList"
                :key="item.id"
                :data-menu-key="item.link"
                @click.prevent="scrollToAnchor(item.link)"
                class="menu-list__item"
            >
                {{ item.title }}
            </li>
        </ul>
    </div>
</template>

<script>

import {SECTIONS} from "@/constants/sections";
export default {
    name: "Menu",
    data() {
        return {
            showMenu: false,
        }
    },
    constants: {SECTIONS},
    computed: {
        menuList() {
            return SECTIONS
        },
        menuClasses() {
            return {
                'menu--is-show': this.showMenu,
            };
        },
    },
    methods: {
        scrollToAnchor(item) {
            document.querySelector('#' + item).scrollIntoView({
                behavior: 'smooth',
            });
            this.showMenu = false;
        },
        handlerShowMenu() {
            this.showMenu = !this.showMenu;
        }
    },
}
</script>

<style lang="scss" scoped>
@import "style/menu";
</style>