<template>
    <header class="header">
        <Menu class="header__menu"/>
    </header>
</template>

<script>
import Menu from "@/components/Menu/Menu.vue";

export default {
    name: "Header",
    components: {Menu}
}
</script>

<style lang="scss" scoped>
    @import "style/header";
</style>