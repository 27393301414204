<template>
    <form id="feedback-form" @submit.prevent="formHandler">
        Как к Вам обращаться:
        <input
            v-model="name"
            type="text"
            name="name"
            required
            placeholder="фамилия имя отчество"
            x-autocompletetype="name"
        >
        Email для связи:
        <input
            v-model="email"
            type="email"
            name="email"
            required
            placeholder="адрес электронной почты"
            x-autocompletetype="email"
        >
        Ваш телефон:
        <input
            v-model="phone"
            type="tel"
            name="phone"
            required
            placeholder="ваш телефон"
            x-autocompletetype="phone"
        >
        Ваше сообщение:
        <textarea
            v-model="message"
            name="message"
            required
            rows="5"
        ></textarea>

        <button
            class="button"
        >
            отправить
        </button>
    </form>
</template>

<script>
import axios from "axios";

export default {
    name: "FeedbackForm",
    data() {
        return {
            name: '',
            email: '',
            phone: '',
            message: '',
            response: '',
            success: '',
        }
    },
    methods: {
        async formHandler() {
            const params = {
                name: this.name,
                email: this.email,
                phone: this.phone,
                message: this.message,
            };

            console.log(params);

            try {
                await axios.post('php/mailer.php', params, {})
                    .then((response) => {
                        this.success = 'Data send successfully';
                        this.response = JSON.stringify(response, null, 2)
                    });
            } catch (error) {
                this.response = 'Error: ' + error.response.status
            }

            this.clearForm();
        },
        clearForm() {
            this.name = '';
            this.email = '';
            this.phone = '';
            this.message = '';
        },
    },
}
</script>

<style lang="scss" scoped>
@import "style/feedbackForm";
</style>